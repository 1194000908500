body {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(22,20,17);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

hr {
  width: 70%; 
  border: 1px yellow solid;
}

img {
  width: 25%;
}


a {
  color: #fff700;
}

a:hover {
  color: #ff8400;
}
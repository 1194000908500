.App {
  text-align: center;
  background: rgb(22,20,17);
  background: rgb(22,20,17);
  background: -moz-linear-gradient(325deg, rgba(22,20,17,0.9857510653409091) 51%, rgba(230,23,23,1) 54%, rgba(230,23,23,1) 55%, rgba(196,14,14,1) 56%, rgba(145,9,9,1) 79%, rgba(230,23,23,1) 80%, rgba(230,23,23,1) 81%, rgba(5,5,4,1) 83%);
  background: -webkit-linear-gradient(325deg, rgba(22,20,17,0.9857510653409091) 51%, rgba(230,23,23,1) 54%, rgba(230,23,23,1) 55%, rgba(196,14,14,1) 56%, rgba(145,9,9,1) 79%, rgba(230,23,23,1) 80%, rgba(230,23,23,1) 81%, rgba(5,5,4,1) 83%);
  background: linear-gradient(325deg, rgba(22,20,17,0.9857510653409091) 51%, rgba(230,23,23,1) 54%, rgba(230,23,23,1) 55%, rgba(196,14,14,1) 56%, rgba(145,9,9,1) 79%, rgba(230,23,23,1) 80%, rgba(230,23,23,1) 81%, rgba(5,5,4,1) 83%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#161411",endColorstr="#050504",GradientType=1);
  background-size: 100% 100vh;
  background-repeat: no-repeat;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(12px + 1vmin);
  color: white;
  padding: 1rem;
}

.Bubble {
  background: rgb(255,252,2);
  background: -moz-linear-gradient(339deg, rgba(255,252,2,0.9857510653409091) 18%, rgba(233,20,20,1) 88%);
  background: -webkit-linear-gradient(339deg, rgba(255,252,2,0.9857510653409091) 18%, rgba(233,20,20,1) 88%);
  background: linear-gradient(339deg, rgba(255,252,2,0.9857510653409091) 18%, rgba(233,20,20,1) 88%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fffc02",endColorstr="#e91414",GradientType=1);
  box-shadow: 1px 1px 8px white;
  color: black;
  padding: 1.5rem;
  border-radius: 45px;
  font-size: 2rem;
  transition: top ease 1s;
}
.Bubble:hover {
  transform: translate(-2px, -2px);
}

a{
  transition: top ease 1s;
}

a:hover {
  transform: translate(-2px, -2px);
}

.Content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
